import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-inner-event',
  templateUrl: './inner-event.component.html',
  styleUrls: ['./inner-event.component.scss'],
})
export class InnerEventComponent {
  @Input() title: string = '';
  @Input() description: string = '';
}
