import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Select, Store } from '@ngxs/store';
import { ActiveToast } from 'ngx-toastr';
import { isEmpty } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { PermissionsService } from '@zonar-ui/auth';
import { PendoService, ZonarUiAnalyticsService } from '@zonar-ui/analytics';
import { FaviconsService } from '@zonar-ui/core';
import { GTCxMobileHelperService } from '@zonar-ui/gtcx-mobile-helper';

import { AppState, GetCompanySettings, GetConfigurationLayoutUI, SetSelectedCompany } from './app.state';
import {
  SidenavFooterConfig,
  SidenavHeaderConfig,
  SidenavMenuConfig,
  SidenavParamsConfig,
} from '@config/sidenav.config';
import { UserCompany } from '@app/models/user.company.model';
import { environment } from '@src/environments/environment';
import { DatadogService } from '@services/data-dog.service';
import { appName } from '@environments/shared';
import { AuthWiringService } from './modules/cts-auth/auth-wiring.service';
import { NotificationService } from './modules/cts-notification/services/notification.service';
import { FeatureToggleService } from './services/feature-toggle.service';
import { NotificationComponent } from './modules/cts-notification/components/notification.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(AppState.getUserPermission) userPermission$: Observable<Record<string, boolean>>;
  @Select(AppState.getCompanyId) currentCompanyId$: Observable<string>;

  loading = true;
  title = appName;

  selectedCompanyId$ = new BehaviorSubject<UserCompany>({
    title: '',
    value: '',
  });
  sidenavHeader = SidenavHeaderConfig;
  sidenavMenu = SidenavMenuConfig;
  sidenavFooter = SidenavFooterConfig;
  sidenavParams = SidenavParamsConfig;

  unauthorizationSnackbar: ActiveToast<NotificationComponent> = null;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public auth: AuthService,
    private datadogService: DatadogService,
    private favicon: FaviconsService,
    private pendoService: PendoService,
    private permissionsService: PermissionsService,
    private authWiringService: AuthWiringService,
    private router: Router,
    private store: Store,
    private zuiAnalytics: ZonarUiAnalyticsService,
    private notificationService: NotificationService,
    private featureToggleService: FeatureToggleService,
    private gtcxMobileHelperService: GTCxMobileHelperService,
  ) {
    this.auth.isAuthenticated$.pipe(
      filter((isAuthenticated) => Boolean(isAuthenticated))
    ).subscribe(() => {
      this.datadogService.startSessionReplayRecording();
      this.featureToggleService.initialize();
    });
  }

  ngOnInit(): void {
    this.authWiringService.initialize();
    this.store.dispatch(new GetConfigurationLayoutUI());

    if (environment.region === 'NA') {
      this.zuiAnalytics.addGtmToDom();
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && environment.region === 'NA') {
        const tag = { event: 'page', pageName: event.url };
        this.zuiAnalytics.pushTag(tag);
      }
    });

    this.authWiringService.getEmptyPerm().subscribe(() => {
      this.showUnAuthorizationMessage();
    });

    this.userPermission$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.currentCompanyId$.pipe(filter(companyId => !!companyId))),
      tap(() => this.notificationService.clear()),
    ).subscribe(([permission, companyId]) => {
      if (isEmpty(permission)) {
        // When has no permission
        this.showUnAuthorizationMessage();
      } else {
        // When has permission
        this.store.dispatch(new GetCompanySettings(companyId));

        // Should init Pendo when switch between companies
        this.pendoService.initialize();
      }
    });

    this.selectedCompanyId$.pipe(
      takeUntil(this.destroy$),
      filter(({ value }) => !!value),
      distinctUntilChanged((prev, curr) => prev.value === curr.value),
    ).subscribe(({ value }) => {
      this.store.dispatch(new SetSelectedCompany(value));
      this.gtcxMobileHelperService.setCurrentCompany(value);
    });
  }

  setCurrentCompanyByFormSelection(event): void {
    this.selectedCompanyId$.next(event);
  }

  showUnAuthorizationMessage() {
    if (this.unauthorizationSnackbar) {
      return;
    }

    this.unauthorizationSnackbar = this.notificationService.showErrorMessage('Not authorized to access page', 'If this is in error, please contact your company administrator to adjust your user permissions.', {
      // Should keep the showing. Don't automatically dismiss by timer or user click!
      tapToDismiss: false,
      disableTimeOut: true,
    });

    this.unauthorizationSnackbar.onHidden.pipe(take(1)).subscribe(() => {
      this.unauthorizationSnackbar = null;
    });
  }

  // #region Side Nav Functions
  onSideNavMobileMenuButtonToggled(event) {
    this.sidenavParams.mobileOpened = event;
  }
  // #endregion Side Nav Functions

  ngOnDestroy(): void {
    this.datadogService.stopSessionReplayRecording();
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
